// types
import { OrderApi, Order } from './../../@types/order';
import { History, HistoryApi } from '../../@types/history';
import { OrderProduct, OrderProductApi } from '../../@types/product';
import { User } from '../../@types/user';
// redux
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// api
import { API_ROOT } from 'api-config';
import axios from 'axios';
// utils
import {
  createOrderedBriefEmployee,
  createOrderedHistory,
  createOrderedOrder,
  createOrderedRecipient,
  createOrderedUser
} from 'redux/formattedEntities';
import { format } from 'date-fns';

// ----------------------------------------------------------------------

type OrderState = {
  isLoading: {
    order: boolean;
    orderSearch: boolean;
  };
  error: {
    order: boolean;
    orderSearch: boolean;
  };
  orderList: Order[];
  orderListSearch: Order[];
  currentOrder: Order | null;
  orderHistory: History[];
  orderProducts: OrderProduct[];
  orderSenders: User[];
  ordersSK: string;
  ordersSearchSK: string;
};

const initialState: OrderState = {
  isLoading: {
    order: false,
    orderSearch: false
  },
  error: {
    order: false,
    orderSearch: false
  },
  orderList: [],
  orderListSearch: [],
  currentOrder: null,
  orderHistory: [],
  orderProducts: [],
  orderSenders: [],
  ordersSK: '',
  ordersSearchSK: ''
};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state, action) {
      if (action.payload === 'order') {
        state.isLoading.order = true;
      } else if (action.payload === 'orderSearch') {
        state.isLoading.orderSearch = true;
      }
    },
    // HAS ERROR
    hasError(state, action) {
      if (action.payload.state === 'order') {
        state.error.order = action.payload.error;
        state.isLoading.order = false;
      } else if (action.payload.state === 'orderSearch') {
        state.error.orderSearch = action.payload.error;
        state.isLoading.orderSearch = false;
      }
    },
    // GET ORDER LIST
    getOrderListSuccess(state, action) {
      state.isLoading.order = false;
      state.orderList = [...state.orderList, action.payload].flat();
    },
    clearOrderList(state) {
      state.orderList = [];
    },
    // GET ORDERS SEARCH RESULTS
    getOrderListSearchSuccess(state, action) {
      if (action.payload.startKey) {
        state.orderListSearch = [...state.orderListSearch, action.payload.orderedList].flat();
      } else {
        state.orderListSearch = action.payload.orderedList;
      }
      state.isLoading.orderSearch = false;
    },
    clearOrderListSearch(state) {
      state.orderListSearch = [];
      state.ordersSearchSK = '';
    },
    // OPTIMISTIC UI UPDATE COURIERS & PACKERS
    toggleStatus(state, action) {
      state.orderList = state.orderList.map((order) => {
        const courier = action.payload.courierId;
        const packer = action.payload.packerId;
        if (order.id === action.payload.id) {
          return {
            ...order,
            courier,
            taker: packer
          };
        } else {
          return {
            ...order
          };
        }
      });
    },
    updateOrderList(state, action) {
      const updatedOrderList = state.orderList.map((order) => {
        if (action.payload.object_id === order.id) {
          return {
            ...order,
            courier: action.payload.courier
              ? createOrderedBriefEmployee(action.payload.courier)
              : null,
            packer: action.payload.taker ? createOrderedBriefEmployee(action.payload.taker) : null,
            status: action.payload.status
          };
        } else return order;
      });
      state.orderList = updatedOrderList;
    },
    // GET ORDER BY ID
    getOrderByIdSuccess(state, action) {
      state.isLoading.order = false;
      state.currentOrder = action.payload;
    },
    clearOrderById(state) {
      state.currentOrder = null;
    },
    // GET SENDER (USER) BY ID
    getOrderSenderByIdSuccess(state, action) {
      state.isLoading.order = false;
      state.orderSenders = [state.orderSenders, action.payload].flat();
    },
    clearOrderSender(state) {
      state.orderSenders = [];
    },
    // GET ORDER HISTORY
    getOrderHistoryListSuccess(state, action) {
      state.isLoading.order = false;
      state.orderHistory = action.payload;
    },
    clearOrderHistory(state) {
      state.orderHistory = [];
    },
    // GET PRODUCTS BY ORDER ID
    getProductsByOrderIdSuccess(state, action) {
      state.isLoading.order = false;
      state.orderProducts = action.payload;
    },
    clearProducts(state) {
      state.orderProducts = [];
    },
    // GET START KEYS
    getOrdersSKSuccess(state, action) {
      state.ordersSK = action.payload;
    },
    getOrdersSearchSKSuccess(state, action) {
      state.ordersSearchSK = action.payload;
    },
    clearOrderSearchSK(state) {
      state.ordersSearchSK = '';
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  clearProducts,
  clearOrderHistory,
  clearOrderListSearch,
  clearOrderList,
  clearOrderSender,
  clearOrderById,
  clearOrderSearchSK,
  updateOrderList
} = slice.actions;

// ----------------------------------------------------------------------

export function getOrderList(startKey: string | undefined | null = '') {
  return async () => {
    dispatch(slice.actions.startLoading('order'));
    try {
      const response = await axios.get(`${API_ROOT}/orders`, {
        params: { start_key: startKey }
      });
      const userIds = response.data.data.items.users_ids;
      userIds.forEach(async (userId: string) => {
        try {
          const response = await axios.get(`${API_ROOT}/users/${userId}/sender`);
          const orderedUser = createOrderedUser(response.data.data.item);
          dispatch(slice.actions.getOrderSenderByIdSuccess(orderedUser));
        } catch (error) {
          dispatch(slice.actions.hasError(error));
        }
      });
      const lastEvalKey: string | null = response.data.data.items.orders.last_evaluated_key;
      const couriers = response.data.data.items.couriers;
      const packers = response.data.data.items.takers;
      const facilities = response.data.data.items.facilities;
      const recipients = response.data.data.items.recipients;
      const ordersList = response.data.data.items.orders.items.map((order: OrderApi): Order => {
        return createOrderedOrder(order, couriers, packers, facilities, recipients, []);
      });
      dispatch(slice.actions.getOrdersSKSuccess(lastEvalKey));
      dispatch(slice.actions.getOrderListSuccess(ordersList));
    } catch (error) {
      dispatch(slice.actions.hasError({ error, state: 'order' }));
    }
  };
}

export function getOrderListSearch(
  startKey: string | undefined | null = '',
  searchQuery?: string | undefined | null
) {
  return async () => {
    dispatch(slice.actions.startLoading('orderSearch'));
    const controller = new AbortController();
    try {
      const response = await axios.get(`${API_ROOT}/orders/search`, {
        params: { q: searchQuery, start_key: startKey },
        signal: controller.signal
      });
      const userIds = response.data.data.items.users_ids;
      userIds.forEach(async (userId: string) => {
        try {
          const response = await axios.get(`${API_ROOT}/users/${userId}/sender`);
          const orderedUser = createOrderedUser(response.data.data.item);
          dispatch(slice.actions.getOrderSenderByIdSuccess(orderedUser));
        } catch (error) {
          dispatch(slice.actions.hasError({ error, state: 'orderSearch' }));
        }
      });
      const lastEvalKey: string | null = response.data.data.items.orders.last_evaluated_key;
      const couriers = response.data.data.items.couriers;
      const packers = response.data.data.items.takers;
      const facilities = response.data.data.items.facilities;
      const recipients = response.data.data.items.recipients;
      const orderedList = response.data.data.items.orders.items.map((order: OrderApi): Order => {
        return createOrderedOrder(order, couriers, packers, facilities, recipients, []);
      });
      dispatch(slice.actions.getOrdersSearchSKSuccess(lastEvalKey));
      dispatch(slice.actions.getOrderListSearchSuccess({ orderedList, startKey }));
    } catch (error) {
      dispatch(slice.actions.hasError({ error, state: 'orderSearch' }));
    }
  };
}

// ----------------------------------------------------------------------

export function getOrderById(objectId: string = '') {
  return async () => {
    dispatch(slice.actions.startLoading('order'));
    try {
      const response = await (await axios.get(`${API_ROOT}/orders/${objectId}`)).data.data.item;
      const facility = await (
        await axios.get(`${API_ROOT}/facilities/${response.recipient.facility_id}`)
      ).data.data.item;
      const regions = await axios.get(`${API_ROOT}/regions`);
      const order: Order = {
        id: response.object_id,
        displayId: response.display_id,
        dateCreated: format(new Date(response.created_at), 'dd/MM/yyyy'),
        deliveryDate: format(new Date(response.delivery_date), 'dd/MM/yyyy'),
        user: response.user_id || 'Гость',
        recipient: createOrderedRecipient(response.recipient, [facility], regions.data.data.items),
        amount: response.cost || 0,
        weight: response.weight || 0,
        status: response.status,
        packer: response.taker ? createOrderedBriefEmployee(response.taker) : null,
        courier: response.courier ? createOrderedBriefEmployee(response.courier) : null
      };
      dispatch(slice.actions.getOrderByIdSuccess(order));
    } catch (error) {
      dispatch(slice.actions.hasError({ error, state: 'order' }));
    }
  };
}

// ----------------------------------------------------------------------

export function getOrderHistory(objectId: string = '') {
  return async () => {
    dispatch(slice.actions.startLoading('order'));
    try {
      const response = await axios.get(`${API_ROOT}/orders/${objectId}/history`);
      const changedBy = response.data.data.items.changed_by;
      const orderedData = response.data.data.items.history.map((history: HistoryApi): History => {
        return createOrderedHistory(history, 'order_id', changedBy);
      });
      dispatch(slice.actions.getOrderHistoryListSuccess(orderedData));
    } catch (error) {
      dispatch(slice.actions.hasError({ error, state: 'order' }));
    }
  };
}

// ----------------------------------------------------------------------

export function getProductsByOrderId(objectId: string | undefined) {
  return async () => {
    dispatch(slice.actions.startLoading('order'));
    try {
      const response = await axios.get(`${API_ROOT}/orders/${objectId}/products`);
      const orderedProducts = response.data.data.item.available_products.map(
        (product: OrderProductApi) => {
          return {
            id: product.object_id,
            title: product.title,
            quantity: product.amount,
            price: product.price_with_margin,
            weight: product.weight,
            imageUrl: product.image_url
          };
        }
      );
      dispatch(slice.actions.getProductsByOrderIdSuccess(orderedProducts));
    } catch (error) {
      dispatch(slice.actions.hasError({ error, state: 'order' }));
    }
  };
}
