import { RegionApi } from './../@types/store';
// types
import {
  Store,
  StoreApi,
  StoreFacility,
  StoreFacilityApi,
  Supplier,
  SupplierApi,
  ProposalApi,
  Proposal,
  CompanyApi,
  Company
} from './../@types/store';
import { HistoryApi, HistoryObject, History } from '../@types/history';
import { Employee, EmployeeApi, EmployeeBrief, EmployeeBriefApi } from '../@types/employees';
import { Facility, FacilityApi, Order, OrderApi } from '../@types/order';
import { User, UserApi, Recipient, RecipientApi } from '../@types/user';
import { Category, CategoryApi, Product, ProductApi } from '../@types/product';
// utils
import { format } from 'date-fns';

// ----------------------------------------------------------------------

// history
export function createOrderedHistory(
  history: HistoryApi,
  historyObject: HistoryObject,
  changedBy: EmployeeBriefApi[]
): History {
  const baseDate = new Date(history.created_at);
  return {
    historyId: history.object_id,
    itemId: history[`${historyObject}` as const],
    action: history.action,
    changeDate: format(
      baseDate.getTime() - baseDate.getTimezoneOffset() * 60 * 1000,
      'dd/MM/yyyy HH:mm'
    ),
    changedBy: changedBy
      .map((employee) => {
        return createOrderedBriefEmployee(employee);
      })
      .find((employee) => employee.id === history.changed_by)
  };
}

// ----------------------------------------------------------------------

// employee
export function createOrderedEmployee(employee: EmployeeApi, store?: string): Employee {
  return {
    id: employee.object_id,
    avatarUrl: employee.image_url,
    name: employee.name,
    login: employee.login,
    phoneNumber: employee.phone_number,
    passportNumber: employee.passport_number,
    passportIssueDate: employee.passport_issue_date,
    passportIssuer: employee.passport_issuer,
    registration: employee.registration,
    status: employee.status,
    role: employee.role,
    storeId: employee.store_id,
    email: employee.email,
    dateCreated: employee.created_at
      ? format(new Date(employee.created_at), 'dd/MM/yyyy HH:mm')
      : null,
    deletedAt: employee.deleted_at,
    store: store || ''
  };
}
export function createOrderedBriefEmployee(employee: EmployeeBriefApi): EmployeeBrief {
  return {
    id: employee.object_id,
    login: employee.login,
    name: employee.name,
    avatarUrl: employee.image_url
  };
}

// ----------------------------------------------------------------------

// order
export function createOrderedOrder(
  order: OrderApi,
  couriers: EmployeeBriefApi[],
  packers: EmployeeBriefApi[],
  facilities: FacilityApi[],
  recipients: RecipientApi[],
  regions: RegionApi[]
): Order {
  return {
    id: order.object_id,
    displayId: order.display_id,
    dateCreated: format(new Date(order.created_at), 'dd/MM/yyyy'),
    deliveryDate: format(new Date(order.delivery_date), 'dd/MM/yyyy'),
    user: order.user_id,
    recipient:
      recipients
        .map((recipient) => createOrderedRecipient(recipient, facilities, regions))
        .find((recipient: Recipient) => recipient.id === order.recipient_id) || null,
    amount: order.cost || 0,
    weight: order.weight || 0,
    status: order.status,
    packer:
      packers
        .map((employee) => {
          return createOrderedBriefEmployee(employee);
        })
        .find((packer: EmployeeBrief) => packer.id === order.taker_id) || null,
    courier:
      couriers
        .map((employee) => {
          return createOrderedBriefEmployee(employee);
        })
        .find((courier: EmployeeBrief) => courier.id === order.courier_id) || null
  };
}

function createOrderedFacility(facility: FacilityApi) {
  return {
    id: facility.object_id,
    name: facility.name
  };
}

export function createOrderedRecipient(
  recipient: RecipientApi,
  facilities: FacilityApi[],
  regions?: RegionApi[]
): Recipient {
  return {
    id: recipient.object_id,
    facilityId: recipient.facility_id,
    name: `${recipient.first_name} ${recipient.patronymic || ''} ${recipient.last_name}`,
    facilityName:
      facilities
        .map((facility) => createOrderedFacility(facility))
        .find((facility: Facility) => facility.id === recipient.facility_id)?.name || 'Неизвестно',
    birthDate: recipient.birthdate
      ? format(new Date(recipient.birthdate), 'dd/MM/yyyy')
      : 'Не указано',
    region:
      regions?.find((region) => region.object_id === recipient.region_id)?.name || 'Неизвестно',
    squadNumber: recipient.squad_number,
    store: `${recipient?.store?.city}, ${recipient?.store?.address}` || 'Неизвестно'
  };
}

// ----------------------------------------------------------------------
// product
export function createOrderedProduct(
  product: ProductApi,
  categories?: CategoryApi[],
  categoryById?: Category
): Product {
  const currentCategory =
    categories &&
    categories
      .map((category: CategoryApi) => createOrderedCategory(category))
      .find((category: Category) => category.id === product.category_id);
  const orderedCategoryById = categoryById ? categoryById.title : '';
  return {
    id: product.object_id,
    imageUrl: product.image_url,
    ingredients: product.ingredients,
    productTypeId: product.product_type_id,
    category: currentCategory?.title || orderedCategoryById,
    categoryId: product.category_id,
    subcategoryId: product.subcategory_id,
    description: product.description,
    weight: product.weight,
    title: product.title,
    isActive: product.is_active,
    deletedAt: product.deleted_at
  };
}

// ----------------------------------------------------------------------

// user
export function createOrderedUser(user: UserApi): User {
  return {
    id: user.object_id,
    cognitoId: user.cognito_id,
    name: `${user.first_name} ${user.patronymic || ''} ${user.last_name}`,
    email: user.email || 'Не указан',
    phoneNumber: user.phone_number || 'Не указан',
    passportNumber: user.passport_number,
    passportIssueDate: format(new Date(user.passport_issue_date), 'dd/MM/yyyy'),
    passportIssuer: user.passport_issuer,
    dateCreated: format(new Date(user.created_at), 'dd/MM/yyyy') || null,
    birthDate: format(new Date(user.birthdate), 'dd/MM/yyyy'),
    ordersNumber: user.orders_count
  };
}

// export function createOrderedRecipient(recipient: RecipientApi): Recipient {
//   return {
//     id: response.data.data.item.object_id,
//         name: `${response.data.data.item.first_name} ${response.data.data.item.patronymic} ${response.data.data.item.last_name}`,
//         facilityName: response.data.data.item.facility
//   }
// }

// ----------------------------------------------------------------------

// store
export function createOrderedStore(store: StoreApi): Store {
  return {
    id: store.object_id,
    address: store.address,
    city: store.city,
    long: store.long,
    lat: store.lat,
    deletedAt: store.deleted_at
  };
}

export function createOrderedStoreFacility(facility: StoreFacilityApi): StoreFacility {
  return {
    id: facility.object_id,
    storeId: facility.store_id,
    regionId: facility.region_id,
    name: facility.name,
    city: facility.city,
    address: facility.address,
    deliveryCost: facility.delivery_cost,
    deliveryDays: facility.delivery_days,
    isActive: facility.is_active,
    deletedAt: facility.deleted_at
  };
}

export function createOrderedSupplier(supplier: SupplierApi): Supplier {
  return {
    id: supplier.object_id,
    title: supplier.title,
    city: supplier.city,
    address: supplier.address,
    defaultMargin: supplier.default_margin,
    companyId: supplier.company_id,
    storeId: supplier.store_id,
    deletedAt: supplier.deleted_at
  };
}

export function createOrderedProposal(
  proposal: ProposalApi,
  categories: CategoryApi[],
  suppliers: SupplierApi[]
): Proposal {
  const category = categories
    .map((category) => createOrderedCategory(category))
    .find((category: Category) => category.id === proposal.product.category_id);
  const supplier = suppliers
    .map((supplier) => createOrderedSupplier(supplier))
    .find((supplier: Supplier) => supplier.id === proposal.supplier_id);
  return {
    id: proposal.object_id,
    proposalId: proposal.proposal.object_id,
    storeId: proposal.proposal.store_id,
    title: proposal.product.title,
    productType: category?.title || '',
    price: proposal.proposal.price,
    imageUrl: proposal.product.image_url,
    weight: proposal.product.weight,
    margin: proposal.proposal.margin,
    totalPrice: proposal.proposal.price_with_margin,
    supplier: supplier || {
      id: '',
      companyId: '',
      storeId: '',
      address: '',
      city: '',
      title: '',
      defaultMargin: 0,
      deletedAt: null
    },
    leftInStock: proposal.proposal.rest_of_goods,
    supplierId: proposal.supplier_id,
    isActive: proposal.proposal.is_active,
    deletedAt: proposal.deleted_at,
    product: createOrderedProduct(proposal.product, categories)
  };
}

export function createOrderedCategory(category: CategoryApi): Category {
  return {
    id: category.object_id,
    title: category.title,
    imageUrl: category.image_url,
    parentId: category.parent_id,
    type: category.type,
    deletedAt: category.deleted_at
  };
}

export function createOrderedCompany(company: CompanyApi): Company {
  return {
    id: company.object_id,
    title: company.title,
    imageUrl: company.image_url,
    legalName: company.juridical_title,
    tin: company.tin,
    deletedAt: company.deleted_at
  };
}
