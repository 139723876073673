// material
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function Logo({ sx }: BoxProps) {
  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg
        width="40"
        height="50"
        viewBox="0 0 546 483"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M389.503 344.622C358.209 316.97 276.718 233.289 273.076 229.651C269.434 226.012 264.34 226.012 258.523 231.106C252.705 236.2 211.956 279.859 203.948 284.225C195.94 288.591 186.48 296.596 171.203 282.77C155.926 268.944 157.381 271.127 142.097 255.119C126.812 239.11 85.335 266.761 103.53 288.591C121.726 310.421 113.722 300.962 140.641 325.702C167.561 350.443 211.952 345.349 232.327 327.885C252.702 310.421 266.527 295.14 266.527 295.14C266.527 295.14 333.472 363.541 356.03 383.188C378.588 402.835 420.796 372.273 389.503 344.622Z"
          fill="#FE9111"
        />
        <g opacity="0.25">
          <path
            d="M389.503 344.621C362.575 317.698 328.375 285.68 320.371 278.404C312.366 271.128 300.724 267.489 286.17 278.404C271.617 289.319 266.527 295.14 266.527 295.14C266.527 295.14 333.472 363.541 356.03 383.188C378.588 402.834 416.43 371.545 389.503 344.621Z"
            fill="#C4C4C4"
          />
          <path
            d="M389.503 344.621C362.575 317.698 328.375 285.68 320.371 278.404C312.366 271.128 300.724 267.489 286.17 278.404C271.617 289.319 266.527 295.14 266.527 295.14C266.527 295.14 333.472 363.541 356.03 383.188C378.588 402.834 416.43 371.545 389.503 344.621Z"
            fill="url(#paint0_linear_1_3)"
          />
        </g>
        <circle cx="340.745" cy="129.961" r="35.6556" fill="#FE9111" />
        <path
          d="M224.32 169.254C203.217 185.991 192.302 200.544 174.111 218.008C155.92 235.472 185.752 270.4 209.766 250.753C233.78 231.106 235.962 223.829 246.877 214.37C257.792 204.91 273.801 204.91 279.622 209.276C285.443 213.642 291.992 223.829 312.367 244.204C332.742 264.579 362.576 257.302 376.402 246.387C390.227 235.472 397.504 225.285 417.878 204.91C438.253 184.535 411.329 143.059 382.223 169.254C353.116 195.45 344.384 204.91 344.384 204.91C344.384 204.91 321.827 180.17 300.724 164.888C279.622 149.607 245.422 152.518 224.32 169.254Z"
          fill="#FE9111"
        />
        <path
          opacity="0.65"
          d="M209.766 182.352C188.664 201.999 192.302 200.544 174.111 218.008C155.92 235.472 185.752 270.4 209.766 250.753C233.78 231.106 235.962 223.829 246.877 214.369C257.792 204.91 273.801 204.91 279.622 209.276C279.622 209.276 258.519 189.629 252.698 185.991C246.877 182.352 230.868 162.706 209.766 182.352Z"
          fill="url(#paint1_linear_1_3)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1_3"
            x1="287.626"
            y1="281.315"
            x2="367.605"
            y2="392.48"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.621518" stopColor="#4E2305" />
            <stop offset="1" stopColor="#FE9111" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1_3"
            x1="280.349"
            y1="183.808"
            x2="187.936"
            y2="251.481"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.177413" stopColor="#4E2305" />
            <stop offset="1" stopColor="#FE9111" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
}
