// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------
export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  confirmPassword: path(ROOTS_AUTH, '/change-password/reset'),
  verify: path(ROOTS_AUTH, '/verify'),
  changePassword: path(ROOTS_AUTH, '/change-password')
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    employees: path(ROOTS_DASHBOARD, '/employees'),
    users: path(ROOTS_DASHBOARD, '/users'),
    orders: path(ROOTS_DASHBOARD, '/orders'),
    products: path(ROOTS_DASHBOARD, '/products'),
    categories: path(ROOTS_DASHBOARD, '/categories'),
    stores: path(ROOTS_DASHBOARD, '/stores'),
    companies: path(ROOTS_DASHBOARD, '/companies')
  },
  employee: {
    root: path(ROOTS_DASHBOARD, '/employees'),
    new: path(ROOTS_DASHBOARD, '/employee/new'),
    details: path(ROOTS_DASHBOARD, `/employee/name/details`)
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/users'),
    details: path(ROOTS_DASHBOARD, '/user/name/details')
  },
  order: {
    root: path(ROOTS_DASHBOARD, '/orders'),
    details: path(ROOTS_DASHBOARD, '/order/id/details')
  },
  product: {
    root: path(ROOTS_DASHBOARD, '/products'),
    details: path(ROOTS_DASHBOARD, '/product/id/details'),
    new: path(ROOTS_DASHBOARD, '/products/new')
  },
  store: {
    root: path(ROOTS_DASHBOARD, '/stores'),
    newStore: path(ROOTS_DASHBOARD, '/stores/new'),
    facilities: path(ROOTS_DASHBOARD, '/stores/id/facilities'),
    newFacility: path(ROOTS_DASHBOARD, '/stores/id/facilities/new'),
    suppliers: path(ROOTS_DASHBOARD, '/stores/id/suppliers'),
    newSupplier: path(ROOTS_DASHBOARD, '/stores/id/suppliers/new'),
    proposals: path(ROOTS_DASHBOARD, '/stores/id/proposals'),
    newProposal: path(ROOTS_DASHBOARD, '/stores/id/proposals/new')
  },
  company: {
    root: path(ROOTS_DASHBOARD, '/companies'),
    newCompany: path(ROOTS_DASHBOARD, '/companies/new')
  },
  category: {
    root: path(ROOTS_DASHBOARD, '/categories'),
    newCompany: path(ROOTS_DASHBOARD, '/categories/new')
  }
};
