import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import userReducer from './slices/user';
import employeeReducer from './slices/employee';
import productReducer from './slices/product';
import orderReducer from './slices/order';
import storeReducer from './slices/store';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const rootReducer = combineReducers({
  user: userReducer,
  product: persistReducer(productPersistConfig, productReducer),
  employee: employeeReducer,
  order: orderReducer,
  store: storeReducer
});

export { rootPersistConfig, rootReducer };
