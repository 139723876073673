// redux
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// api
import axios from 'axios';
import { API_ROOT } from 'api-config';
// types
import { Recipient, RecipientApi, User, UserApi } from '../../@types/user';
import { Order, OrderApi } from '../../@types/order';
// utils
import {
  createOrderedOrder,
  createOrderedRecipient,
  createOrderedUser
} from 'redux/formattedEntities';

// ----------------------------------------------------------------------

type UserState = {
  isLoading: {
    user: boolean;
    userSearch: boolean;
    userOrders: boolean;
  };
  error: {
    user: boolean;
    userSearch: boolean;
    userOrders: boolean;
  };
  userList: User[];
  userListSearch: User[];
  currentUser: User | null;
  recipientList: Recipient[];
  userRecipients: Recipient[];
  userOrders: Order[];
  usersSK: string | null;
  usersSearchSK: string | null;
  userOrdersSK: string | null;
};

const initialState: UserState = {
  isLoading: {
    user: false,
    userSearch: false,
    userOrders: false
  },
  error: {
    user: false,
    userSearch: false,
    userOrders: false
  },
  userList: [],
  userListSearch: [],
  currentUser: null,
  recipientList: [],
  userRecipients: [],
  userOrders: [],
  usersSK: '',
  usersSearchSK: '',
  userOrdersSK: ''
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state, action) {
      if (action.payload === 'user') {
        state.isLoading.user = true;
      }
      if (action.payload === 'userSearch') {
        state.isLoading.userSearch = true;
      }
      if (action.payload === 'userOrders') {
        state.isLoading.userOrders = true;
      }
    },
    // HAS ERROR
    hasError(state, action) {
      if (action.payload.state === 'user') {
        state.error.user = action.payload.error;
        state.isLoading.user = false;
      }
      if (action.payload.state === 'userSearch') {
        state.error.userSearch = action.payload.error;
        state.isLoading.userSearch = false;
      }
      if (action.payload.state === 'userOrders') {
        state.error.userSearch = action.payload.error;
        state.isLoading.userSearch = false;
      }
    },
    // GET USER LIST
    getUserListSuccess(state, action) {
      state.isLoading.user = false;
      state.userList = [...state.userList, action.payload].flat();
    },
    // GET USER LIST
    getUserListSearchSuccess(state, action) {
      if (action.payload.startKey) {
        state.userListSearch = [...state.userListSearch, action.payload.orderedList].flat();
      } else {
        state.userListSearch = action.payload.orderedList;
      }
      state.isLoading.userSearch = false;
    },
    clearUserListSearch(state) {
      state.userListSearch = [];
      state.usersSearchSK = '';
    },
    getUserByIdSuccess(state, action) {
      state.isLoading.user = false;
      state.currentUser = action.payload;
    },
    clearUserList(state) {
      state.userList = [];
    },
    getUsersSKSuccess(state, action) {
      state.usersSK = action.payload;
    },
    getUsersSearchSKSuccess(state, action) {
      state.usersSearchSK = action.payload;
    },
    getUserOrdersSKSuccess(state, action) {
      state.userOrdersSK = action.payload;
    },
    clearUserSearchSK(state) {
      state.usersSearchSK = '';
    },
    clearUserSK(state) {
      state.usersSK = '';
    },
    // GET USER RECIPIENT BY ID
    getRecipientByIdSuccess(state, action) {
      state.isLoading.user = false;
      state.userRecipients = action.payload;
    },
    getUserOrdersSuccess(state, action) {
      state.isLoading.userOrders = false;
      state.userOrders = [...state.userOrders, action.payload].flat();
    }
  }
});

// Reducer
export default slice.reducer;
// actions
export const { clearUserList, clearUserListSearch, clearUserSearchSK, clearUserSK } = slice.actions;

// ----------------------------------------------------------------------

export function getUserList(startKey: string | undefined | null = '') {
  return async () => {
    dispatch(slice.actions.startLoading('user'));
    try {
      const response = await axios.get(`${API_ROOT}/users`, {
        params: { start_key: startKey }
      });
      const lastEvalKey: string | null = response.data.data.last_evaluated_key;
      const orderedList = response.data.data.items.map((user: UserApi) => {
        return createOrderedUser(user);
      });
      dispatch(slice.actions.getUsersSKSuccess(lastEvalKey));
      dispatch(slice.actions.getUserListSuccess(orderedList));
    } catch (error) {
      if (axios.isCancel(error)) return;
      dispatch(slice.actions.hasError({ error, state: 'user' }));
    }
  };
}

// ----------------------------------------------------------------------

export function getUserListSearch(
  startKey: string | undefined | null = '',
  searchQuery: string | undefined
) {
  return async () => {
    dispatch(slice.actions.startLoading('userSearch'));
    try {
      const response = await axios.get(`${API_ROOT}/users/search`, {
        params: { q: searchQuery, start_key: startKey }
      });
      const lastEvalKey: string | null = response.data.data.last_evaluated_key;
      const orderedList = response.data.data.items.map((user: UserApi) => {
        return createOrderedUser(user);
      });
      dispatch(slice.actions.getUsersSearchSKSuccess(lastEvalKey));
      dispatch(slice.actions.getUserListSearchSuccess({ orderedList, startKey }));
    } catch (error) {
      if (axios.isCancel(error)) return;
      dispatch(slice.actions.hasError({ error, state: 'userSearch' }));
    }
  };
}

// ----------------------------------------------------------------------

export function getUserById(userId: string) {
  return async () => {
    dispatch(slice.actions.startLoading('user'));
    try {
      const response = await axios.get(`${API_ROOT}/users/${userId}`);
      const orderedUser = createOrderedUser(response.data.data.item);

      dispatch(slice.actions.getUserByIdSuccess(orderedUser));
    } catch (error) {
      if (axios.isCancel(error)) return;
      dispatch(slice.actions.hasError({ error, state: 'user' }));
    }
  };
}

// ----------------------------------------------------------------------

export function getRecipientsByUserId(cognitoId: string | undefined) {
  return async () => {
    dispatch(slice.actions.startLoading('user'));
    try {
      const response = await axios.get(`${API_ROOT}/users/${cognitoId}/recipients`);
      const facilities = response.data.data.item.facilities;
      const orderedRecipients = response.data.data.item.recipients.map(
        (recipient: RecipientApi): Recipient => {
          return createOrderedRecipient(recipient, facilities);
        }
      );
      dispatch(slice.actions.getRecipientByIdSuccess(orderedRecipients));
    } catch (error) {
      dispatch(slice.actions.hasError({ error, state: 'user' }));
    }
  };
}

// ----------------------------------------------------------------------

export function getOrdersByUserId(
  cognitoId: string | undefined,
  startKey: string | undefined | null = ''
) {
  return async () => {
    dispatch(slice.actions.startLoading('userOrders'));
    try {
      const response = await axios.get(`${API_ROOT}/users/${cognitoId}/orders`, {
        params: { start_key: startKey }
      });
      const facilities = response.data.data.item.facilities;
      const recipients = response.data.data.item.recipients;
      const lastEvalKey = response.data.data.item.orders.last_evaluated_key;
      const ordersList = response.data.data.item.orders.items.map((order: OrderApi): Order => {
        return createOrderedOrder(order, [], [], facilities, recipients, []);
      });
      dispatch(slice.actions.getUserOrdersSKSuccess(lastEvalKey));
      dispatch(slice.actions.getUserOrdersSuccess(ordersList));
    } catch (error) {
      dispatch(slice.actions.hasError({ error, state: 'userOrders' }));
    }
  };
}
