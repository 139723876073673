import { StoreApi } from './../../@types/store';
// types
import { Employee, EmployeeApi } from '../../@types/employees';
import { History, HistoryApi } from '../../@types/history';
// api
import axios from 'axios';
import { API_ROOT } from 'api-config';
// redux
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import { filter } from 'lodash';
import { createOrderedEmployee, createOrderedHistory } from 'redux/formattedEntities';

// ----------------------------------------------------------------------

type EmployeeState = {
  isLoading: {
    employee: boolean;
    deliveryEmployee: boolean;
    employeeSearch: boolean;
    history: boolean;
  };
  error: {
    employee: boolean;
    deliveryEmployee: boolean;
    employeeSearch: boolean;
    history: boolean;
  };
  employeeList: Employee[];
  currentEmployee: Employee | null;
  employeeListSearch: Employee[];
  courierList: Employee[];
  packerList: Employee[];
  employeeHistory: History[];
  employeesSK: string;
  employeesSearchSK: string;
  deliveryEmployeesSK: string;
};

const initialState: EmployeeState = {
  isLoading: {
    employee: false,
    deliveryEmployee: false,
    employeeSearch: false,
    history: false
  },
  error: {
    employee: false,
    deliveryEmployee: false,
    employeeSearch: false,
    history: false
  },
  employeeList: [],
  currentEmployee: null,
  employeeListSearch: [],
  courierList: [],
  packerList: [],
  employeeHistory: [],
  employeesSK: '',
  employeesSearchSK: '',
  deliveryEmployeesSK: ''
};

const slice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state, action) {
      switch (action.payload) {
        case 'employee':
          state.isLoading.employee = true;
          break;
        case 'deliveryEmployee':
          state.isLoading.deliveryEmployee = true;
          break;
        case 'employeeSearch':
          state.isLoading.employeeSearch = true;
          break;
        case 'history':
          state.isLoading.history = true;
          break;
      }
    },
    // HAS ERROR
    hasError(state, action) {
      switch (action.payload.state) {
        case 'employee':
          state.error.employee = action.payload.error;
          state.isLoading.employee = false;
          break;
        case 'deliveryEmployee':
          state.error.deliveryEmployee = action.payload.error;
          state.isLoading.deliveryEmployee = false;
          break;
        case 'employeeSearch':
          state.error.employeeSearch = action.payload.error;
          state.isLoading.employeeSearch = false;
          break;
        case 'history':
          state.error.history = action.payload.error;
          state.isLoading.history = false;
          break;
      }
    },
    // DELETE EMPLOYEES
    deleteEmployee(state, action) {
      const deleteEmployee = filter(
        state.employeeList,
        (employee) => employee.id !== action.payload
      );
      const deleteEmployeeSearch = filter(
        state.employeeListSearch,
        (employee) => employee.id !== action.payload
      );
      state.employeeList = deleteEmployee;
      state.employeeListSearch = deleteEmployeeSearch;
    },
    // OPTIMISTIC UI UPDATE
    updateEmployeeList(state, action) {
      const updatedEmployee = createOrderedEmployee(action.payload);
      const updatedEmployeeList = state.employeeList.map((employee) => {
        if (employee.id === updatedEmployee.id) {
          return updatedEmployee;
        } else {
          return employee;
        }
      });
      state.employeeList = updatedEmployeeList;
      state.currentEmployee = updatedEmployee;
    },
    addEmployee(state, action) {
      state.employeeList = [createOrderedEmployee(action.payload), ...state.employeeList].flat();
    },
    // GET EMPLOYEE BY ID
    getEmployeeByIdSuccess(state, action) {
      state.isLoading.employee = false;
      state.currentEmployee = action.payload;
    },
    // GET EMPLOYEE LIST
    getEmployeeListSuccess(state, action) {
      state.isLoading.employee = false;
      state.employeeList = [...state.employeeList, action.payload].flat();
    },
    // GET EMPLOYEE SEARCH RESULTS
    getEmployeeListSearchSuccess(state, action) {
      if (action.payload.startKey) {
        state.employeeListSearch = [...state.employeeListSearch, action.payload.orderedList].flat();
      } else {
        state.employeeListSearch = action.payload.orderedList;
      }
      state.isLoading.employeeSearch = false;

      // state.isLoading.employeeSearch = false;
      // state.employeeListSearch = [...state.employeeListSearch, action.payload]
      //   .flat()
      //   .reduce(
      //     (items, item) =>
      //       items.find((x: Employee) => x.id === item.id) ? [...items] : [...items, item],
      //     []
      //   );
    },
    clearEmployeeListSearch(state) {
      state.employeeListSearch = [];
      state.employeesSearchSK = '';
    },
    // GET COURIERS & PACKERS
    getDeliveryEmployeeListSuccess(state, action) {
      state.isLoading.deliveryEmployee = false;
      state.courierList = action.payload.couriers;
      state.packerList = action.payload.packers;
    },
    // GET EMPLOYEE HISTORY
    getEmployeeHistorySuccess(state, action) {
      state.isLoading.history = false;
      state.employeeHistory = action.payload;
    },
    clearEmployeeHistory(state) {
      state.employeeHistory = [];
    },
    // GET START KEYS
    getEmployeesSKSuccess(state, action) {
      state.employeesSK = action.payload;
    },
    getEmployeesSearchSKSuccess(state, action) {
      state.employeesSearchSK = action.payload;
    },
    getDeliveryEmployeesSKSuccess(state, action) {
      state.isLoading.deliveryEmployee = false;
      state.deliveryEmployeesSK = action.payload;
    },
    clearEmployeeSearchSK(state) {
      state.employeesSearchSK = '';
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  deleteEmployee,
  clearEmployeeHistory,
  clearEmployeeListSearch,
  updateEmployeeList,
  addEmployee,
  clearEmployeeSearchSK
} = slice.actions;

// ----------------------------------------------------------------------

export function getEmployeeList(startKey: string | undefined | null = '') {
  return async () => {
    dispatch(slice.actions.startLoading('employee'));
    try {
      const response = await axios.get(`${API_ROOT}/employees`, {
        params: { start_key: startKey }
      });
      const lastEvalKey: string | null = response.data.data.items.last_evaluated_key;
      const orderedData = response.data.data.items
        .map((employee: EmployeeApi): Employee => {
          return createOrderedEmployee(employee);
        })
        .filter((employee: Employee) => !employee.deletedAt);
      dispatch(slice.actions.getEmployeesSKSuccess(lastEvalKey));
      dispatch(slice.actions.getEmployeeListSuccess(orderedData));
    } catch (error) {
      dispatch(slice.actions.hasError({ error, state: 'employee' }));
    }
  };
}

// ----------------------------------------------------------------------

export function getEmployeeById(id: string = '') {
  return async () => {
    dispatch(slice.actions.startLoading('employee'));
    try {
      const response = await axios.get(`${API_ROOT}/employees/${id}`);
      const orderedData = createOrderedEmployee(response.data.data.item);
      dispatch(slice.actions.getEmployeeByIdSuccess(orderedData));
    } catch (error) {
      dispatch(slice.actions.hasError({ error, state: 'employee' }));
    }
  };
}

// ----------------------------------------------------------------------

export function getDeliveryEmployeeList(startKey: string | undefined | null = '') {
  return async () => {
    dispatch(slice.actions.startLoading('deliveryEmployee'));
    try {
      const couriers = await axios.get(`${API_ROOT}/employees`, {
        params: { start_key: startKey, role: 'courier' }
      });
      const takers = await axios.get(`${API_ROOT}/employees`, {
        params: { start_key: startKey, role: 'taker' }
      });
      const lastEvalKey: string | null = couriers.data.data.items.last_evaluated_key;
      const storesResponse = await axios.get(`${API_ROOT}/stores`);
      const stores = storesResponse.data.data.items;
      const orderedCouriers = couriers.data.data.items.map((employee: EmployeeApi): Employee => {
        const store = stores.find((store: StoreApi) => store.object_id === employee.store_id);

        const storeData = store
          ? `${store.city} ${store.address}`
          : 'Сотрудник не привязан к складу';
        return createOrderedEmployee(employee, storeData);
      });
      const orderedPackers = takers.data.data.items
        .map((employee: EmployeeApi): Employee => {
          const store = stores.find((store: StoreApi) => store.object_id === employee.store_id);

          const storeData = store
            ? `${store.city} ${store.address}`
            : 'Сотрудник не привязан к складу';
          return createOrderedEmployee(employee, storeData);
        })
        .filter((employee: Employee) => !employee.deletedAt);
      dispatch(slice.actions.getDeliveryEmployeesSKSuccess(lastEvalKey));
      dispatch(
        slice.actions.getDeliveryEmployeeListSuccess({
          packers: orderedPackers,
          couriers: orderedCouriers
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError({ error, state: 'deliveryEmployee' }));
    }
  };
}

// ----------------------------------------------------------------------

export function getEmployeeListSearch(
  startKey: string | undefined | null = '',
  searchQuery?: string | undefined | null
) {
  return async () => {
    dispatch(slice.actions.startLoading('employeeSearch'));
    const controller = new AbortController();
    try {
      const response = await axios.get(`${API_ROOT}/employees/search`, {
        params: { q: searchQuery, start_key: startKey },
        signal: controller.signal
      });
      const lastEvalKey: string | null = response.data.data.items.last_evaluated_key;
      const orderedList = response.data.data.items
        .map((employee: EmployeeApi): Employee => {
          return createOrderedEmployee(employee);
        })
        .filter((employee: Employee) => !employee.deletedAt);
      dispatch(slice.actions.getEmployeesSearchSKSuccess(lastEvalKey));
      dispatch(slice.actions.getEmployeeListSearchSuccess({ orderedList, startKey }));
    } catch (error) {
      dispatch(slice.actions.hasError({ error, state: 'employeeSearch' }));
    }
  };
}

// ----------------------------------------------------------------------

export function getEmployeeHistory(objectId: string = '') {
  return async () => {
    dispatch(slice.actions.startLoading('history'));
    try {
      const response = await axios.get(`${API_ROOT}/employees/${objectId}/history`);
      const changedBy = response.data.data.items.changed_by;
      const orderedHistory = response.data.data.items.history.map(
        (history: HistoryApi): History => {
          return createOrderedHistory(history, 'employee_id', changedBy);
        }
      );
      dispatch(slice.actions.getEmployeeHistorySuccess(orderedHistory));
    } catch (error) {
      dispatch(slice.actions.hasError({ error, state: 'history' }));
    }
  };
}
