// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  employees: getIcon('ic_employees'),
  users: getIcon('ic_users'),
  orders: getIcon('ic_orders'),
  products: getIcon('ic_ecommerce'),
  categories: getIcon('ic_categories'),
  stores: getIcon('ic_store'),
  companies: getIcon('ic_company')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Главное',
    items: [
      { title: 'Сотрудники', path: PATH_DASHBOARD.general.employees, icon: ICONS.employees },
      { title: 'Покупатели', path: PATH_DASHBOARD.general.users, icon: ICONS.users },
      { title: 'Заказы', path: PATH_DASHBOARD.general.orders, icon: ICONS.orders },
      { title: 'Категории', path: PATH_DASHBOARD.general.categories, icon: ICONS.categories },
      { title: 'Товары', path: PATH_DASHBOARD.general.products, icon: ICONS.products },
      { title: 'Склады', path: PATH_DASHBOARD.general.stores, icon: ICONS.stores },
      { title: 'Компании', path: PATH_DASHBOARD.general.companies, icon: ICONS.companies }
    ]
  }
];

export default sidebarConfig;
