import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component: any) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Auth routes
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
        { path: 'change-password', element: <ChangePassword /> },
        { path: 'change-password/reset', element: <ChangePassword /> }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/orders" replace /> },
        { path: 'employees', element: <Employees /> },
        { path: 'users', element: <Users /> },
        { path: 'orders', element: <Orders /> },
        { path: 'products', element: <Products /> },
        { path: 'categories', element: <Categories /> },
        { path: 'categories/:id/edit', element: <CreateCategory /> },
        { path: 'categories/:id/new', element: <CreateCategory /> },
        { path: 'stores', element: <Stores /> },
        { path: 'companies', element: <Companies /> },
        { path: 'employees/:id/edit', element: <EmployeeCreate /> },
        { path: 'employee/new', element: <EmployeeCreate /> },
        { path: 'employees/:id/details', element: <EmployeeDetails /> },
        { path: 'users/:id/details', element: <UserDetails /> },
        { path: 'orders/:id/details', element: <OrderDetails /> },
        { path: 'products/:id/details', element: <ProductDetails /> },
        { path: 'products/new', element: <ProductCreate /> },
        { path: 'products/:id/edit', element: <ProductCreate /> },
        { path: 'stores/:id/facilities', element: <Facilities /> },
        { path: 'stores/:id/suppliers', element: <Suppliers /> },
        { path: 'stores/:id/proposals', element: <Proposals /> },
        { path: 'stores/:storeId/proposals/:proposalId/details', element: <ProposalDetails /> },
        { path: 'stores/:storeId/proposals/new', element: <CreateProposal /> },
        { path: 'stores/:storeId/proposals/:proposalId/edit', element: <CreateProposal /> },
        { path: 'stores/:id/edit', element: <CreateStore /> },
        { path: 'stores/new', element: <CreateStore /> },
        { path: 'stores/:storeId/facilities/:facilityId/edit', element: <CreateFacility /> },
        { path: 'stores/:storeId/facilities/new', element: <CreateFacility /> },
        { path: 'stores/:storeId/facilities/:facilityId/details', element: <FacilityDetails /> },
        { path: 'stores/:id/suppliers/:supplierId/edit', element: <CreateSupplier /> },
        { path: 'stores/:id/suppliers/new', element: <CreateSupplier /> },
        { path: 'companies/new', element: <CreateCompany /> },
        { path: 'companies/:id/edit', element: <CreateCompany /> }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <Navigate to="/dashboard/orders" replace />
        </AuthGuard>
      )
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS
// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
const ChangePassword = Loadable(lazy(() => import('../pages/authentication/ChangePassword')));

// Dashboard
const Employees = Loadable(lazy(() => import('../pages/dashboard/employee/Employees')));
const Users = Loadable(lazy(() => import('../pages/dashboard/user/Users')));
const Orders = Loadable(lazy(() => import('../pages/dashboard/order/Orders')));
const Products = Loadable(lazy(() => import('../pages/dashboard/product/Products')));
const Categories = Loadable(lazy(() => import('../pages/dashboard/category/Categories')));
const CreateCategory = Loadable(lazy(() => import('../pages/dashboard/category/CreateCategory')));
const Stores = Loadable(lazy(() => import('../pages/dashboard//store/Stores')));
const EmployeeCreate = Loadable(lazy(() => import('../pages/dashboard/employee/EmployeeCreate')));
const EmployeeDetails = Loadable(lazy(() => import('../pages/dashboard/employee/EmployeeDetails')));
const UserDetails = Loadable(lazy(() => import('../pages/dashboard/user/UserDetails')));
const OrderDetails = Loadable(lazy(() => import('../pages/dashboard/order/OrderDetails')));
const ProductDetails = Loadable(lazy(() => import('../pages/dashboard/product/ProductDetails')));
const ProductCreate = Loadable(lazy(() => import('../pages/dashboard/product/ProductCreate')));
const FacilityDetails = Loadable(lazy(() => import('../pages/dashboard/store/FacilityDetails')));
const Facilities = Loadable(lazy(() => import('../pages/dashboard/store/Facilities')));
const Suppliers = Loadable(lazy(() => import('../pages/dashboard/store/Suppliers')));
const Proposals = Loadable(lazy(() => import('../pages/dashboard/store/Proposals')));
const ProposalDetails = Loadable(lazy(() => import('../pages/dashboard/store/ProposalDetails')));
const Companies = Loadable(lazy(() => import('../pages/dashboard/company/Companies')));
const CreateStore = Loadable(lazy(() => import('../pages/dashboard/store/CreateStore')));
const CreateFacility = Loadable(lazy(() => import('../pages/dashboard/store/CreateFacility')));
const CreateSupplier = Loadable(lazy(() => import('../pages/dashboard/store/CreateSupplier')));
const CreateProposal = Loadable(lazy(() => import('../pages/dashboard/store/CreateProposal')));
const CreateCompany = Loadable(lazy(() => import('../pages/dashboard/company/CreateCompany')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
