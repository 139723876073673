import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// types
import {
  Company,
  CompanyApi,
  StoreFacility,
  StoreFacilityApi,
  Proposal,
  ProposalApi,
  Region,
  RegionApi,
  Store,
  StoreApi,
  Supplier,
  SupplierApi
} from '../../@types/store';
// api
import axios from 'axios';
import { API_ROOT } from 'api-config';
// utils
import {
  createOrderedCompany,
  createOrderedProduct,
  createOrderedProposal,
  createOrderedStore,
  createOrderedStoreFacility,
  createOrderedSupplier
} from 'redux/formattedEntities';
import { filter } from 'lodash';

type StoreLoading = {
  store: boolean;
  company: boolean;
  proposal: boolean;
  proposalSearch: boolean;
  supplier: boolean;
  region: boolean;
  facility: boolean;
};

type StoreState = {
  isLoading: StoreLoading;
  error: StoreLoading;
  storeList: Store[];
  storeById: Store | undefined;
  facilityList: StoreFacility[];
  facilityById: StoreFacility | null;
  supplierList: Supplier[];
  supplierById: Supplier | null;
  previousId: string;
  proposalList: Proposal[];
  proposalListSearch: Proposal[];
  proposalById: Proposal | undefined;
  regionList: Region[];
  companyList: Company[];
  companyById: Company | null;
  SKS: {
    facilities: string;
    suppliers: string;
    proposals: string;
    stores: string;
    companies: string;
    proposalsSearch: string;
  };
};

const initialState: StoreState = {
  isLoading: {
    store: false,
    company: false,
    proposal: false,
    proposalSearch: false,
    supplier: false,
    region: false,
    facility: false
  },
  error: {
    store: false,
    company: false,
    proposal: false,
    proposalSearch: false,
    supplier: false,
    region: false,
    facility: false
  },
  storeList: [],
  storeById: undefined,
  facilityList: [],
  facilityById: null,
  supplierList: [],
  supplierById: null,
  previousId: '',
  proposalList: [],
  proposalListSearch: [],
  proposalById: undefined,
  regionList: [],
  companyList: [],
  companyById: null,
  SKS: {
    facilities: '',
    suppliers: '',
    proposals: '',
    stores: '',
    companies: '',
    proposalsSearch: ''
  }
};

const slice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    // LOADING
    startLoading(state, action) {
      if (action.payload === 'store') {
        state.isLoading.store = true;
      }
      if (action.payload === 'company') {
        state.isLoading.company = true;
      }
      if (action.payload === 'proposal') {
        state.isLoading.proposal = true;
      }
      if (action.payload === 'proposalSearch') {
        state.isLoading.proposalSearch = true;
      }
      if (action.payload === 'supplier') {
        state.isLoading.supplier = true;
      }
      if (action.payload === 'region') {
        state.isLoading.region = true;
      }
      if (action.payload === 'facility') {
        state.isLoading.facility = true;
      }
    },
    // ERROR
    hasError(state, action) {
      if (action.payload.type === 'store') {
        state.error.store = action.payload.error;
        state.isLoading.store = false;
      }
      if (action.payload.type === 'company') {
        state.error.company = action.payload.error;
        state.isLoading.company = false;
      }
      if (action.payload.type === 'proposal') {
        state.error.proposal = action.payload.error;
        state.isLoading.proposal = false;
      }
      if (action.payload.type === 'proposalSearch') {
        state.error.proposalSearch = action.payload.error;
        state.isLoading.proposal = false;
      }
      if (action.payload.type === 'supplier') {
        state.error.supplier = action.payload.error;
        state.isLoading.supplier = false;
      }
      if (action.payload.type === 'region') {
        state.error.region = action.payload.error;
        state.isLoading.region = false;
      }
      if (action.payload.type === 'facility') {
        state.error.facility = action.payload.error;
        state.isLoading.facility = false;
      }
    },
    // PREVIOUS ID
    getPreviousId(state, action) {
      state.previousId = action.payload;
    },
    // STORE
    getStoreListSuccess(state, action) {
      state.storeList = [...state.storeList, action.payload].flat();
      state.isLoading.store = false;
    },
    getStoreByIdSuccess(state, action) {
      state.storeById = action.payload;
      state.isLoading.store = false;
    },
    clearStoreById(state) {
      state.storeById = undefined;
    },
    updateStore(state, action) {
      const updatedStore = createOrderedStore(action.payload);
      const isEdit = state.storeList.find((store) => store.id === updatedStore.id);

      if (isEdit) {
        state.storeList = state.storeList.map((store) => {
          if (store.id === updatedStore.id) return updatedStore;
          else return store;
        });
      } else {
        state.storeList = [updatedStore, ...state.storeList].flat();
      }
      state.storeById = updatedStore;
    },
    deleteStore(state, action) {
      const deleteStore = filter(state.storeList, (store) => store.id !== action.payload);
      state.storeList = deleteStore;
    },
    // FACILITY
    getStoreFacilitiesSuccess(state, action) {
      state.facilityList = [...state.facilityList, action.payload].flat();
      state.isLoading.facility = false;
    },
    updateFacilities(state, action) {
      const updatedFacility = createOrderedStoreFacility(action.payload);
      const isEdit = state.facilityList.find((facility) => facility.id === updatedFacility.id);

      if (isEdit) {
        state.facilityList = state.facilityList.map((facility) => {
          if (facility.id === updatedFacility.id) return updatedFacility;
          else return facility;
        });
      } else {
        state.facilityList = [updatedFacility, ...state.facilityList].flat();
      }
      state.facilityById = updatedFacility;
    },
    deleteFacility(state, action) {
      const deleteFacility = filter(
        state.facilityList,
        (facility) => facility.id !== action.payload
      );
      state.facilityList = deleteFacility;
    },
    clearFacilities(state) {
      state.facilityList = [];
    },
    getStoreFacilityByIdSuccess(state, action) {
      state.facilityById = action.payload;
      state.isLoading.facility = false;
    },
    // SUPPLIER
    getStoreSuppliersSuccess(state, action) {
      state.supplierList = [...state.supplierList, action.payload].flat();
      state.isLoading.supplier = false;
    },
    updateSuppliers(state, action) {
      const updatedSupplier = createOrderedSupplier(action.payload);
      const isEdit = state.supplierList.find((supplier) => supplier.id === updatedSupplier.id);

      if (isEdit) {
        state.supplierList = state.supplierList.map((supplier) => {
          if (supplier.id === updatedSupplier.id) return updatedSupplier;
          else return supplier;
        });
      } else {
        state.supplierList = [updatedSupplier, ...state.supplierList].flat();
      }
      state.supplierById = updatedSupplier;
    },
    deleteSupplier(state, action) {
      const deleteSupplier = filter(
        state.supplierList,
        (supplier) => supplier.id !== action.payload
      );
      state.supplierList = deleteSupplier;
    },
    getStoreSupplierByIdSuccess(state, action) {
      state.supplierById = action.payload;
      state.isLoading.supplier = false;
    },
    clearSuppliers(state) {
      state.supplierList = [];
    },
    // PROPOSAL
    getStoreProposalsSuccess(state, action) {
      state.proposalList = [...state.proposalList, action.payload].flat();
      state.isLoading.proposal = false;
    },
    clearProposals(state) {
      state.proposalList = [];
    },
    deleteProposal(state, action) {
      const deleteProposal = filter(
        state.proposalList,
        (proposal) => proposal.id !== action.payload
      );
      const deleteProposalSearch = filter(
        state.proposalListSearch,
        (proposal) => proposal.id !== action.payload
      );
      state.proposalList = deleteProposal;
      state.proposalListSearch = deleteProposalSearch;
    },
    getStoreProposalsSearchSuccess(state, action) {
      if (action.payload.startKey) {
        state.proposalListSearch = [...state.proposalListSearch, action.payload.orderedList].flat();
      } else {
        state.proposalListSearch = action.payload.orderedList;
      }
      state.isLoading.proposalSearch = false;
    },
    clearProposalListSearch(state) {
      state.proposalListSearch = [];
      state.SKS.proposalsSearch = '';
    },
    getProposalByIdSuccess(state, action) {
      state.proposalById = action.payload;
      state.isLoading.proposal = false;
    },
    updateProposal(state, action) {
      if (state.proposalById) {
        state.proposalById = {
          ...state.proposalById,
          margin: action.payload.margin,
          price: action.payload.price,
          totalPrice: action.payload.price_with_margin,
          supplierId: action.payload.supplier_id,
          leftInStock: action.payload.rest_of_goods,
          isActive: action.payload.is_active
        };
      }
      state.proposalList = state.proposalList.map((proposal) => {
        if (proposal.proposalId === action.payload.object_id) {
          return {
            ...proposal,
            margin: action.payload.margin,
            price: action.payload.price,
            totalPrice: action.payload.price_with_margin,
            supplierId: action.payload.supplier_id,
            productId: action.payload.product_id,
            leftInStock: action.payload.rest_of_goods,
            isActive: action.payload.is_active
          };
        } else return proposal;
      });
    },
    // REGION
    getRegionListSuccess(state, action) {
      state.regionList = action.payload;
      state.isLoading.region = false;
    },
    // COMPANY
    getCompanyListSuccess(state, action) {
      state.companyList = [...state.companyList, action.payload].flat();
      state.isLoading.company = false;
    },
    getCompanyByIdSuccess(state, action) {
      state.companyById = action.payload;
      state.isLoading.company = false;
    },
    updateCompany(state, action) {
      const updatedCompany = createOrderedCompany(action.payload);
      const isEdit = state.companyList.find((company) => company.id === updatedCompany.id);

      if (isEdit) {
        state.companyList = state.companyList.map((company) => {
          if (company.id === updatedCompany.id) return updatedCompany;
          else return company;
        });
      } else {
        state.companyList = [updatedCompany, ...state.companyList].flat();
      }
      state.companyById = updatedCompany;
    },
    deleteCompany(state, action) {
      const deleteCompany = filter(state.companyList, (company) => company.id !== action.payload);
      state.companyList = deleteCompany;
    },
    // GET START KEYS
    getFacilitiesSKSuccess(state, action) {
      state.SKS.facilities = action.payload;
      state.isLoading.facility = false;
    },
    clearFacilitiesSK(state) {
      state.SKS.facilities = '';
    },
    getSuppliersSKSuccess(state, action) {
      state.SKS.suppliers = action.payload;
      state.isLoading.supplier = false;
    },
    clearSuppliersSK(state) {
      state.SKS.suppliers = '';
    },
    getProposalsSKSuccess(state, action) {
      state.SKS.proposals = action.payload;
    },
    getProposalsSearchSKSuccess(state, action) {
      state.SKS.proposalsSearch = action.payload;
    },
    clearProposalsSK(state) {
      state.SKS.proposals = '';
    },
    clearProposalsSearchSK(state) {
      state.SKS.proposalsSearch = '';
    },
    getStoresSKSuccess(state, action) {
      state.SKS.stores = action.payload;
      state.isLoading.store = false;
    },
    getCompaniesSKSuccess(state, action) {
      state.isLoading.company = false;
      state.SKS.companies = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;
export const {
  clearProposalListSearch,
  clearSuppliersSK,
  clearSuppliers,
  clearProposals,
  clearProposalsSK,
  clearFacilities,
  clearFacilitiesSK,
  clearProposalsSearchSK,
  updateStore,
  deleteStore,
  getPreviousId,
  deleteProposal,
  updateProposal,
  clearStoreById,
  updateFacilities,
  deleteFacility,
  updateSuppliers,
  deleteSupplier,
  deleteCompany,
  updateCompany
} = slice.actions;

export function getStoreList(startKey: string | undefined | null = '') {
  return async () => {
    dispatch(slice.actions.startLoading('store'));
    try {
      const response = await axios.get(`${API_ROOT}/stores`, {
        params: {
          start_key: startKey
        }
      });
      const lastEvalKey: string | null = response.data.data.items.last_evaluated_key;
      const orderedList = response.data.data.items.map((store: StoreApi): Store => {
        return createOrderedStore(store);
      });
      dispatch(slice.actions.getStoresSKSuccess(lastEvalKey));
      dispatch(slice.actions.getStoreListSuccess(orderedList));
    } catch (error) {
      dispatch(slice.actions.hasError({ error, type: 'store' }));
    }
  };
}

export function getStoreById(storeId: string) {
  return async () => {
    dispatch(slice.actions.startLoading('store'));
    try {
      const response = await axios.get(`${API_ROOT}/store/${storeId}`);
      const orderedStore = createOrderedStore(response.data.data.item);
      dispatch(slice.actions.getStoreByIdSuccess(orderedStore));
    } catch (error) {
      dispatch(slice.actions.hasError({ error, type: 'store' }));
    }
  };
}

export function getStoreFacilities(
  objectId: string = '',
  startKey: string | undefined | null = ''
) {
  return async () => {
    dispatch(slice.actions.startLoading('facility'));
    try {
      const response = await axios.get(`${API_ROOT}/store/${objectId}/facilities`, {
        params: { start_key: startKey }
      });
      const lastEvalKey: string | null = response.data.data.items.last_evaluated_key;
      const orderedList = response.data.data.items.map(
        (facility: StoreFacilityApi): StoreFacility => {
          return createOrderedStoreFacility(facility);
        }
      );
      dispatch(slice.actions.getFacilitiesSKSuccess(lastEvalKey));
      dispatch(slice.actions.getStoreFacilitiesSuccess(orderedList));
    } catch (error) {
      dispatch(slice.actions.hasError({ error, type: 'facility' }));
    }
  };
}

export function getStoreFacilityById(objectId: string = '') {
  return async () => {
    dispatch(slice.actions.startLoading('facility'));
    try {
      const response = await axios.get(`${API_ROOT}/facilities/${objectId}`);
      const facility = createOrderedStoreFacility(response.data.data.item);
      dispatch(slice.actions.getStoreFacilityByIdSuccess(facility));
    } catch (error) {
      dispatch(slice.actions.hasError({ error, type: 'facility' }));
    }
  };
}

export function getStoreSuppliers(objectId: string = '', startKey: string | undefined | null = '') {
  return async () => {
    dispatch(slice.actions.startLoading('supplier'));
    try {
      const response = await axios.get(`${API_ROOT}/store/${objectId}/suppliers`, {
        params: { start_key: startKey }
      });
      const lastEvalKey: string | null = response.data.data.items.last_evaluated_key;
      const orderedList = response.data.data.items.map((supplier: SupplierApi): Supplier => {
        return createOrderedSupplier(supplier);
      });
      dispatch(slice.actions.getSuppliersSKSuccess(lastEvalKey));
      dispatch(slice.actions.getStoreSuppliersSuccess(orderedList));
    } catch (error) {
      dispatch(slice.actions.hasError({ error, type: 'supplier' }));
    }
  };
}

export function getStoreSupplierById(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading('supplier'));
    try {
      const response = await axios.get(`${API_ROOT}/supplier/${id}`);
      const supplier = createOrderedSupplier(response.data.data.item);
      dispatch(slice.actions.getStoreSupplierByIdSuccess(supplier));
    } catch (error) {
      dispatch(slice.actions.hasError({ error, type: 'supplier' }));
    }
  };
}

export function getStoreProposals(objectId: string = '', startKey: string | undefined | null = '') {
  return async () => {
    dispatch(slice.actions.startLoading('proposal'));
    try {
      const response = await axios.get(`${API_ROOT}/store/${objectId}/proposals`, {
        params: {
          start_key: startKey
        }
      });
      const lastEvalKey: string | null = response.data.data.items.last_evaluated_key;
      const categories = response.data.data.items.categories;
      const suppliers = response.data.data.items.suppliers;
      const proposals = response.data.data.items.proposals.items.map((proposal: ProposalApi) => {
        return createOrderedProposal(proposal, categories, suppliers);
      });

      dispatch(slice.actions.getProposalsSKSuccess(lastEvalKey));
      dispatch(slice.actions.getStoreProposalsSuccess(proposals));
    } catch (error) {
      dispatch(slice.actions.hasError({ error, type: 'proposal' }));
    }
  };
}

export function getStoreProposalsSearch(
  startKey: string | undefined | null = '',
  storeId: string | undefined | null = '',
  searchQuery: string | undefined | null
) {
  return async () => {
    dispatch(slice.actions.startLoading('proposalSearch'));
    try {
      const response = await axios.get(`${API_ROOT}/store/${storeId}/proposals/search`, {
        params: {
          start_key: startKey,
          q: searchQuery
        }
      });
      const lastEvalKey: string | null = response.data.data.items.last_evaluated_key;
      const categories = response.data.data.items.categories;
      const suppliers = response.data.data.items.suppliers;
      const orderedList = response.data.data.items.proposals.items.map((proposal: ProposalApi) => {
        return createOrderedProposal(proposal, categories, suppliers);
      });
      dispatch(slice.actions.getProposalsSearchSKSuccess(lastEvalKey));
      dispatch(slice.actions.getStoreProposalsSearchSuccess({ orderedList, startKey }));
    } catch (error) {
      dispatch(slice.actions.hasError({ error, type: 'proposalSearch' }));
    }
  };
}

export function getProposalById(objectId: string = '') {
  return async () => {
    dispatch(slice.actions.startLoading('proposal'));
    try {
      const response = await axios.get(`${API_ROOT}/proposal/${objectId}`);
      const proposal = response.data.data.item;
      const category = await axios.get(
        `${API_ROOT}/categories/${proposal.product.product_type_id}`
      );

      const orderedProposal: Proposal = {
        id: proposal.object_id,
        proposalId: proposal.proposal.object_id,
        storeId: proposal.proposal.store_id,
        title: proposal.product.title,
        productType: proposal.product.product_type_id,
        price: proposal.proposal.price,
        totalPrice: proposal.proposal.price_with_margin,
        margin: proposal.proposal.margin,
        imageUrl: proposal.product.image_url,
        weight: proposal.product.weight,
        supplier: proposal.supplier_id,
        supplierId: proposal.supplier_id,
        leftInStock: proposal.proposal.rest_of_goods,
        isActive: proposal.proposal.is_active,
        deletedAt: proposal.deleted_at,
        product: createOrderedProduct(proposal.product, [], category.data.data.item)
      };

      dispatch(slice.actions.getProposalByIdSuccess(orderedProposal));
    } catch (error) {
      dispatch(slice.actions.hasError({ error, type: 'proposal' }));
    }
  };
}

export function getRegionList() {
  return async () => {
    dispatch(slice.actions.startLoading('region'));
    try {
      const response = await axios.get(`${API_ROOT}/regions`);
      const orderedList = response.data.data.items.map((item: RegionApi): Region => {
        return {
          id: item.object_id,
          name: item.name
        };
      });
      dispatch(slice.actions.getRegionListSuccess(orderedList));
    } catch (error) {
      dispatch(slice.actions.hasError({ error, type: 'region' }));
    }
  };
}

export function getCompanyList(startKey: string | undefined | null = '') {
  return async () => {
    dispatch(slice.actions.startLoading('company'));
    try {
      const response = await axios.get(`${API_ROOT}/companies`, {
        params: { start_key: startKey }
      });
      const lastEvalKey: string | null = response.data.data.items.last_evaluated_key;
      const orderedList = response.data.data.items.map((company: CompanyApi): Company => {
        return createOrderedCompany(company);
      });
      dispatch(slice.actions.getCompaniesSKSuccess(lastEvalKey));
      dispatch(slice.actions.getCompanyListSuccess(orderedList));
    } catch (error) {
      dispatch(slice.actions.hasError({ error, type: 'company' }));
    }
  };
}

export function getCompanyById(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading('company'));
    try {
      const response = await axios.get(`${API_ROOT}/company/${id}`);
      const orderedCompany = createOrderedCompany(response.data.data.item);
      dispatch(slice.actions.getCompanyByIdSuccess(orderedCompany));
    } catch (error) {
      dispatch(slice.actions.hasError({ error, type: 'company' }));
    }
  };
}
