// types
import { Product, ProductApi, CategoryApi, Category } from '../../@types/product';
import { HistoryApi, History } from '../../@types/history';
// redux
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// api
import { API_ROOT } from './../../api-config';
import axios from 'axios';
// format data
import {
  createOrderedCategory,
  createOrderedHistory,
  createOrderedProduct
} from 'redux/formattedEntities';

// ----------------------------------------------------------------------

type ProductState = {
  isLoading: {
    product: boolean;
    productSearch: boolean;
    category: boolean;
  };
  error: {
    product: boolean;
    productSearch: boolean;
    category: boolean;
  };
  productList: Product[];
  productListSearch: Product[];
  productById: Product | undefined;
  productHistory: History[];
  categoryList: Category[];
  categoryById: Category | null;
  categoriesSK: string | null;
  productsSK: string | null;
  productsSearchSK: string | null;
};

const initialState: ProductState = {
  isLoading: {
    product: false,
    productSearch: false,
    category: false
  },
  error: {
    product: false,
    productSearch: false,
    category: false
  },
  productList: [],
  productListSearch: [],
  productHistory: [],
  productById: undefined,
  categoryList: [],
  categoryById: null,
  categoriesSK: '',
  productsSK: '',
  productsSearchSK: ''
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state, action) {
      if (action.payload === 'product') {
        state.isLoading.product = true;
      } else if (action.payload === 'productSearch') {
        state.isLoading.productSearch = true;
      } else if (action.payload === 'category') {
        state.isLoading.category = true;
      }
    },
    // HAS ERROR
    hasError(state, action) {
      if (action.payload.state === 'product') {
        state.error.product = action.payload.error;
        state.isLoading.product = false;
      } else if (action.payload.state === 'productSearch') {
        state.error.productSearch = action.payload.error;
        state.isLoading.productSearch = false;
      } else if (action.payload.state === 'category') {
        state.error.category = action.payload.error;
        state.isLoading.category = false;
      }
    },
    // GET PRODUCT LIST
    getProductListSuccess(state, action) {
      state.productList = [...state.productList, action.payload].flat();
      state.isLoading.product = false;
    },
    // CLEAR PRODUCT LIST
    clearProductList(state) {
      state.productList = [];
    },
    // GET PRODUCTS SEARCH RESULTS
    getProductListSearchSuccess(state, action) {
      if (action.payload.startKey) {
        state.productListSearch = [...state.productListSearch, action.payload.orderedList].flat();
      } else {
        state.productListSearch = action.payload.orderedList;
      }
      state.isLoading.productSearch = false;
    },
    // CLEAR PRODUCT SEARCH RESULTS
    clearProductListSearch(state) {
      state.productListSearch = [];
      state.productsSearchSK = '';
    },
    // OPTIMISTIC UI UPDATE
    updateProductList(state, action) {
      const updatedProduct = createOrderedProduct(
        action.payload.product,
        [],
        action.payload.category
      );
      const updatedProductList = state.productList.map((product) => {
        if (product.id === updatedProduct.id) {
          return updatedProduct;
        } else {
          return product;
        }
      });
      state.productList = updatedProductList;
      state.productById = updatedProduct;
    },
    addProduct(state, action) {
      state.productList = [
        createOrderedProduct(action.payload.product, [], action.payload.category),
        ...state.productList
      ].flat();
    },
    // GET PRODUCT BY ID
    getProductByIdSuccess(state, action) {
      state.productById = action.payload;
      state.isLoading.product = false;
    },
    // GET PRODUCT HISTORY
    getProductHistoryListSuccess(state, action) {
      state.productHistory = action.payload;
      state.isLoading.product = false;
    },
    // GET ALL CATEGORIES
    getCategoryListSuccess(state, action) {
      state.categoryList = [...state.categoryList, action.payload].flat();
      state.isLoading.category = false;
    },
    clearCategoryList(state) {
      state.categoryList = [];
    },
    // GET CATEGORY BY ID
    getCategoryByIdSuccess(state, action) {
      state.categoryById = action.payload;
      state.isLoading.category = false;
    },
    clearCategoryById(state) {
      state.categoryById = null;
    },
    updateCategory(state, action) {
      state.categoryList = state.categoryList.map((category) => {
        if (category.id === action.payload.id) {
          return {
            ...action.payload
          };
        } else return category;
      });
    },
    addCategory(state, action) {
      state.categoryList = [action.payload, ...state.categoryList].flat();
    },
    deleteCategory(state, action) {
      state.categoryList = state.categoryList.filter((category) => category.id !== action.payload);
    },
    // GET START KEYS
    getCategoriesSKSuccess(state, action) {
      state.categoriesSK = action.payload;
      state.isLoading.category = false;
    },
    clearCategoriesSK(state) {
      state.categoriesSK = '';
    },
    getProductsSKSuccess(state, action) {
      state.productsSK = action.payload;
      state.isLoading.product = false;
    },
    getProductsSearchSKSuccess(state, action) {
      state.productsSearchSK = action.payload;
    },
    clearProductSearchSK(state) {
      state.productsSearchSK = '';
    }
  }
});

// Reducer
export default slice.reducer;
// Actions
export const {
  clearProductList,
  clearProductListSearch,
  updateProductList,
  clearCategoryList,
  clearCategoriesSK,
  updateCategory,
  addCategory,
  deleteCategory,
  clearCategoryById,
  clearProductSearchSK,
  addProduct
} = slice.actions;

// ----------------------------------------------------------------------

export function getProductList(startKey: string | undefined | null = '') {
  return async () => {
    dispatch(slice.actions.startLoading('product'));
    try {
      const response = await axios.get(`${API_ROOT}/products`, {
        params: { start_key: startKey }
      });
      const lastEvalKey: string | null = response.data.data.items.products.last_evaluated_key;
      const categories = response.data.data.items.categories;
      const orderedList = response.data.data.items.products.items.map(
        (product: ProductApi): Product => {
          return createOrderedProduct(product, categories);
        }
      );

      dispatch(slice.actions.getProductsSKSuccess(lastEvalKey));
      dispatch(slice.actions.getProductListSuccess(orderedList));
    } catch (error) {
      dispatch(slice.actions.hasError({ error, state: 'product' }));
    }
  };
}

// ----------------------------------------------------------------------

export function getProductListSearch(
  startKey: string | undefined | null = '',
  searchQuery?: string | undefined | null,
  storeQuery?: string,
  productId?: string
) {
  return async () => {
    dispatch(slice.actions.startLoading('productSearch'));
    try {
      const response = await axios.get(`${API_ROOT}/products/search`, {
        params: {
          q: searchQuery,
          start_key: startKey,
          store_id: storeQuery || '',
          product_id: productId || null
        }
      });

      const lastEvalKey: string | null = response.data.data.items.products.last_evaluated_key;
      const categories = response.data.data.items.categories;
      const orderedList = response.data.data.items.products.items.map(
        (product: ProductApi): Product => {
          return createOrderedProduct(product, categories);
        }
      );
      dispatch(slice.actions.getProductListSearchSuccess({ orderedList, startKey }));
      dispatch(slice.actions.getProductsSearchSKSuccess(lastEvalKey));
    } catch (error) {
      dispatch(slice.actions.hasError({ error, state: 'productSearch' }));
    }
  };
}

// ----------------------------------------------------------------------

export function getProductById(productId: string) {
  return async () => {
    dispatch(slice.actions.startLoading('product'));
    try {
      const response = await axios.get(`${API_ROOT}/products/${productId}`);
      const category = await axios.get(
        `${API_ROOT}/categories/${response.data.data.item.category_id}`
      );
      const orderedProduct = createOrderedProduct(
        response.data.data.item,
        [],
        category.data.data.item
      );
      dispatch(slice.actions.getProductByIdSuccess(orderedProduct));
    } catch (error) {
      dispatch(slice.actions.hasError({ error, state: 'product' }));
    }
  };
}

// ----------------------------------------------------------------------

export function getProductHistory(objectId: string = '') {
  return async () => {
    dispatch(slice.actions.startLoading('product'));
    try {
      const response = await axios.get(`${API_ROOT}/products/${objectId}/history`);
      const changedBy = response.data.data.items.changed_by;
      const orderedData = response.data.data.items.history.map((history: HistoryApi): History => {
        return createOrderedHistory(history, 'product_id', changedBy);
      });
      dispatch(slice.actions.getProductHistoryListSuccess(orderedData));
    } catch (error) {
      dispatch(slice.actions.hasError({ error, state: 'product' }));
    }
  };
}

// ----------------------------------------------------------------------

export function getCategoryList(startKey: string | null = '') {
  return async () => {
    dispatch(slice.actions.startLoading('category'));
    try {
      const response = await axios.get(`${API_ROOT}/categories-initial`, {
        params: { start_key: startKey }
      });
      const categoryStartKey = response.data.data.last_evaluated_key;
      const orderedData = response.data.data.items.map((category: CategoryApi): Category => {
        return createOrderedCategory(category);
      });
      dispatch(slice.actions.getCategoriesSKSuccess(categoryStartKey));
      dispatch(slice.actions.getCategoryListSuccess(orderedData));
    } catch (error) {
      dispatch(slice.actions.hasError({ error, state: 'category' }));
    }
  };
}

export function getCategoryById(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading('category'));
    try {
      const response = await axios.get(`${API_ROOT}/categories/${id}`);
      const orderedData = createOrderedCategory(response.data.data.item);
      dispatch(slice.actions.getCategoryByIdSuccess(orderedData));
    } catch (error) {
      dispatch(slice.actions.hasError({ error, state: 'category' }));
    }
  };
}
